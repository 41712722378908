import gql from 'graphql-tag'
import { ELEMENT_FIELDS, PAGE_INFO } from './catalogQuery'
import { ELEMENT_SEO_FIELDS, SECTION_SEO_FIELDS } from './seoQuery'

export const JOURNAL_DETAIL = gql`
  fragment journalDetail on Element {
    id
    name
    code
    previewText
    detailText
    createdAt
    activeFrom
    detailPicture {
      id
      url
    }
    previewPicture {
      id
      url
    }
    sections {
      id
      code
      name
    }

    ...elementSeoFields

    propertyValuesRelation {
      id
      property {
        id
        code
      }

      targetElements {
        ...elementFields
        priceBase
        priceBaseWithDiscount
        activeFrom
        activeTo
        active

        sku(where: { active: { equals: true } }) {
          id
          name
          priceBase
          priceBaseWithDiscount
        }
      }
    }
  }
  ${ELEMENT_FIELDS}
  ${ELEMENT_SEO_FIELDS}
`

export const JOURNAL_ITEM_FEILDS = gql`
  fragment journalItemFields on Element {
    id
    name
    code
    previewText
    detailPicture {
      id
      url
    }
    previewPicture {
      id
      url
    }
    sections {
      id
      name
      code
    }

    propertyValuesEnum {
      id
      value
      property {
        id
        code
      }
    }
  }
`

export const JOURNAL_ITEMS = gql`
  query journalItems(
    $where: ElementWhereInput
    $last: Int
    $first: Int
    $order: ElementOrderByInput
    $after: String
  ) {
    elements(
      where: $where
      last: $last
      first: $first
      order: $order
      after: $after
    ) {
      edges {
        cursor
        node {
          ...journalItemFields
        }
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${JOURNAL_ITEM_FEILDS}
  ${PAGE_INFO}
`

export const JOURNAL_ITEMS_FEED = gql`
  query journalItemsFeed(
    $where: ElementWhereInput
    $last: Int
    $first: Int
    $order: ElementOrderByInput
    $after: String
  ) {
    elements(
      where: $where
      last: $last
      first: $first
      order: $order
      after: $after
    ) {
      edges {
        cursor
        node {
          ...journalDetail
        }
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${JOURNAL_DETAIL}
  ${PAGE_INFO}
`

export const JOURNAL_YOUTUBE_ITEMS = gql`
  query journalYoutubeItems(
    $where: ElementWhereInput
    $first: Int
    $order: ElementOrderByInput
  ) {
    elements(where: $where, first: $first, order: $order) {
      nodes {
        id
        name

        previewPicture {
          id
          url
        }

        propertyValues {
          id
          value
          property {
            id
            code
          }
        }
      }
    }
  }
`

export const JOURNAL_ITEM = gql`
  query journalItem($where: ElementWhereInput!) {
    element(where: $where) {
      ...journalDetail
    }
  }
  ${JOURNAL_DETAIL}
`

export const JOURNAL_SECTIONS = gql`
  query journalSections(
    $where: SectionWhereInput
    $first: Int
    $order: SectionOrderByInput
  ) {
    sections(where: $where, first: $first, order: $order) {
      nodes {
        id
        name
        code

        elements(where: { active: { equals: true } }, first: 1) {
          id
        }

        ...sectionSeoFields
      }
    }
  }
  ${SECTION_SEO_FIELDS}
`
