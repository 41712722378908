import React, { FC } from 'react'
import NewIcon from '../../../public/static/icons/new-product.svg'
import Link from 'next/link'
import css from './JournalItem.module.scss'
import { journalItems_elements_edges_node } from '../../../__generated__/journalItems'
import cx from 'classnames'
import sanitizeHtml from 'sanitize-html'
import { getPropByCode } from '../../../lib/gql'
import Image from '../../common/Image/Image'
interface IJournalItemProps {
  post: journalItems_elements_edges_node
  size: 'big' | 'medium' | 'small'
  imageKind?: 'high' | 'square' | undefined
}

const JournalItem: FC<IJournalItemProps> = ({ post, size, imageKind }) => {
  const newPost = !!getPropByCode(post?.propertyValuesEnum, 'NOVETLY')
  return (
    <li className={cx(css.wrapper, css[size])}>
      <Link
        href={`/journal/${post?.sections[0]?.code}/${post?.code}/`}
        passHref
      >
        <a>
          <figure
            className={cx(
              css.cover,
              imageKind === 'high' && css.high,
              imageKind === 'square' && css.square,
            )}
          >
            <Image
              src={post?.previewPicture?.url || post?.detailPicture?.url}
              width={438}
              height={220}
              layout="responsive"
              objectFit="cover"
              alt="Изображение"
            />
          </figure>
          <div className={css.text}>
            <p className={css.category}>
              {post?.sections && post?.sections[0]?.name}
            </p>
            <p className={css.heading}>{post?.name}</p>
            <div
              className={css.announcement}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(post?.previewText),
              }}
            />

            {newPost && <NewIcon className={css.new} />}
          </div>
        </a>
      </Link>
    </li>
  )
}

export default JournalItem
